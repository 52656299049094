exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-homepages-infotechno-jsx": () => import("./../../../src/pages/homepages/infotechno.jsx" /* webpackChunkName: "component---src-pages-homepages-infotechno-jsx" */),
  "component---src-pages-innerpages-about-us-jsx": () => import("./../../../src/pages/innerpages/about-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-about-us-jsx" */),
  "component---src-pages-innerpages-careers-jsx": () => import("./../../../src/pages/innerpages/careers.jsx" /* webpackChunkName: "component---src-pages-innerpages-careers-jsx" */),
  "component---src-pages-innerpages-case-studies-jsx": () => import("./../../../src/pages/innerpages/case-studies.jsx" /* webpackChunkName: "component---src-pages-innerpages-case-studies-jsx" */),
  "component---src-pages-innerpages-contact-us-jsx": () => import("./../../../src/pages/innerpages/contact-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-contact-us-jsx" */),
  "component---src-pages-innerpages-faq-jsx": () => import("./../../../src/pages/innerpages/faq.jsx" /* webpackChunkName: "component---src-pages-innerpages-faq-jsx" */),
  "component---src-pages-innerpages-it-services-jsx": () => import("./../../../src/pages/innerpages/it-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-it-services-jsx" */),
  "component---src-pages-innerpages-it-solutions-jsx": () => import("./../../../src/pages/innerpages/it-solutions.jsx" /* webpackChunkName: "component---src-pages-innerpages-it-solutions-jsx" */),
  "component---src-pages-innerpages-leadership-jsx": () => import("./../../../src/pages/innerpages/leadership.jsx" /* webpackChunkName: "component---src-pages-innerpages-leadership-jsx" */),
  "component---src-pages-innerpages-our-history-jsx": () => import("./../../../src/pages/innerpages/our-history.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-history-jsx" */),
  "component---src-pages-innerpages-pricing-plan-jsx": () => import("./../../../src/pages/innerpages/pricing-plan.jsx" /* webpackChunkName: "component---src-pages-innerpages-pricing-plan-jsx" */),
  "component---src-pages-innerpages-why-choose-us-jsx": () => import("./../../../src/pages/innerpages/why-choose-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-why-choose-us-jsx" */),
  "component---src-templates-blog-archive-index-jsx": () => import("./../../../src/templates/blog-archive/index.jsx" /* webpackChunkName: "component---src-templates-blog-archive-index-jsx" */),
  "component---src-templates-blog-category-index-jsx": () => import("./../../../src/templates/blog-category/index.jsx" /* webpackChunkName: "component---src-templates-blog-category-index-jsx" */),
  "component---src-templates-blog-single-index-jsx": () => import("./../../../src/templates/blog-single/index.jsx" /* webpackChunkName: "component---src-templates-blog-single-index-jsx" */),
  "component---src-templates-blog-tag-index-jsx": () => import("./../../../src/templates/blog-tag/index.jsx" /* webpackChunkName: "component---src-templates-blog-tag-index-jsx" */),
  "component---src-templates-case-study-index-jsx": () => import("./../../../src/templates/case-study/index.jsx" /* webpackChunkName: "component---src-templates-case-study-index-jsx" */),
  "component---src-templates-it-solution-index-jsx": () => import("./../../../src/templates/it-solution/index.jsx" /* webpackChunkName: "component---src-templates-it-solution-index-jsx" */)
}

