import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import BlogMain from "@components/blog/layout-main-01";
import { SectionTitleType, BlogMainType, BlogType } from "@utils/types";
import { BlogWrapper, ListItem, ListLink } from "./style";


const BlogMainArea = ({ data }) => {
    return (
        <BlogWrapper>
            <Container>
                <Row>
                    <Col lg={4}>
                        {data?.section_title && (
                            <SectionTitle
                                textAlign="left"
                                mb="30px"
                                title={data.section_title?.title}
                                subtitle={data.section_title?.subtitle}
                            />
                        )}
                        {data?.recentBlogs && (
                            <ul>
                                {data.recentBlogs?.map((blog) => (
                                    <ListItem key={`/blog/${blog.slug}`}>
                                        <ListLink path={`/blog/${blog.slug}`}>
                                            <i className="icon icon-1 far fa-long-arrow-right"></i>
                                            <i className="icon icon-2 far fa-long-arrow-right"></i>
                                            <span>{blog.title}</span>
                                        </ListLink>
                                    </ListItem>
                                ))}
                            </ul>
                        )}
                    </Col>
                    <Col lg={8}>
                        {data?.featuredBlogs && (
                            <Row>
                                {data.featuredBlogs?.map((blog) => (
                                    <Col
                                        md={6}
                                        key={blog.slug}
                                        mt={["50px", null, 0]}
                                    >
                                        <BlogMain
                                            title={blog.title}
                                            postedAt={blog.date}
                                            slug={blog.slug}
                                            uri={blog.uri}
                                            excerpt={blog.excerpt}
                                            image={blog.featuredImage}
                                            author={blog.author.node}
                                            className="single-blog-item blog-grid"
                                        />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Container>
        </BlogWrapper>
    );
};

BlogMainArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        featuredBlogs: PropTypes.arrayOf(PropTypes.shape(BlogMainType)),
        recentBlogs: PropTypes.arrayOf(PropTypes.shape(BlogType)),
    }),
};

export default BlogMainArea;
