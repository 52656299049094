import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
import Image from "@ui/image";
import { FeaturedImageType } from "@utils/types";
import { StyledMedia, StyledThumb } from "./style";

const BlogMainMedia = ({
    image,
    author,
    className,
    title,
    ...rest
}) => {
    return (
        <StyledMedia className={cn("Blog-media", className)} {...rest}>
            {image?.node.localFile && (
                <StyledThumb>
                    <Image src={image.node.localFile} alt={image?.node?.altText || title} />
                </StyledThumb>
            )}
        </StyledMedia>
    );
};

BlogMainMedia.propTypes = {
    image: PropTypes.shape(FeaturedImageType),
    author: PropTypes.object,
    title: PropTypes.string,
    className: PropTypes.string,
};

export default BlogMainMedia;
