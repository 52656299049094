import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BlogMain from "@components/blog/layout-main-02";
import SectionTitle from "@ui/section-title";
import { SectionTitleType, BlogMainType } from "@utils/types";
import { BlogBox } from "./style";

const BlogMainArea = ({ data }) => {
    return (
        <Fragment>
            <div className="blog Area">
                {data?.section_title && (
                    <SectionTitle
                        mb={["45px", null, "65px"]}
                        {...data.section_title}
                    />
                )}
                {data?.blogs?.map((blog) => (
                    <BlogBox key={blog.slug}>
                        <BlogMain
                            uri={blog.uri}
                            title={blog.title}
                            slug={blog.slug}
                            excerpt={blog.excerpt}
                            postedAt={blog.date}
                            author={blog.author}
                            categories={blog.categories}
                            image={blog.featuredImage}
                            siteUrl={data.siteUrl}
                        />
                    </BlogBox>
                ))}
            </div>
        </Fragment>
    );
};

BlogMainArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        blogs: PropTypes.arrayOf(PropTypes.shape(BlogMainType)),
        siteUrl: PropTypes.string,
    }),
};

export default BlogMainArea;
