import React from "react";
import PropTypes from "prop-types";
import { StyledAuthor, StyledAvatar, StyledInfo, StyledBio } from "./style";

const AuthorDetails = ({ avatar, name, bio }) => {
    return (
        <StyledAuthor>
            <StyledAvatar>
                {avatar?.url && (
                    <img src={avatar.url} alt={avatar?.title || name} style={{'maxHeight': '100px', 'borderRadius': '50%'}} />
                )}
            </StyledAvatar>
            <StyledInfo>
                {name && <h6>{name}</h6>}
                {bio && <StyledBio>{bio}</StyledBio>}
            </StyledInfo>
        </StyledAuthor>
    );
};

AuthorDetails.propTypes = {
    avatar: PropTypes.shape({}),
    name: PropTypes.string,
    bio: PropTypes.string,
};

export default AuthorDetails;
