import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
import Anchor from "@ui/anchor";
import Button from "@ui/button";
import Text from "@ui/text";
import Image from "@ui/image";
import BlogMeta from "../blog-meta";
import VideoThumb from "../video-thumb";
import BlogQuote from "../blog-quote";
import { BlogFormatType, BlogPostedAtType, ImageType } from "@utils/types";
import parse from "html-react-parser";
import {
    BlogWrapper,
    BlogMedia,
    BlogThumb,
    BlogInfo,
    BlogHeader,
    BlogTitle,
    BlogExcerpt,
    ReadMoreBtn,
} from "./style";

const BlogMain = ({
    title,
    postedAt,
    image,
    author,
    excerpt,
    slug,
    uri,
    className,
    ...restProps
}) => {
    return (
        <BlogWrapper className={cn(className, "blog")} {...restProps}>
            <BlogMedia>
                {image?.node.localFile && (
                    <BlogThumb>
                        <Anchor path={`/blog/${slug}`}>
                            <Image src={image.node.localFile} alt={image?.altText || title} />
                        </Anchor>
                    </BlogThumb>
                )}
            </BlogMedia>
            <BlogInfo>
                <BlogHeader>
                    {postedAt && (
                        <BlogMeta
                            mb="7px"
                            text={postedAt}
                            path={`#`}
                            icon="far fa-calendar-alt"
                        />
                    )}
                    {title && (
                        <BlogTitle>
                            <Anchor path={`/blog/${slug}`}>
                            {title}
                            </Anchor>
                        </BlogTitle>
                    )}
                </BlogHeader>
                {excerpt && (
                    <BlogExcerpt>
                        {parse(excerpt)}
                    </BlogExcerpt>
                )}
                <ReadMoreBtn>
                    <Button
                        icon="far fa-long-arrow-right"
                        path={`/blog/${slug}`}
                        variant="texted"
                        icondistance="4px"
                        fontWeight={500}
                    >
                        Read More
                    </Button>
                </ReadMoreBtn>
            </BlogInfo>
        </BlogWrapper>
    );
};

BlogMain.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
    postedAt: PropTypes.string,
    image: PropTypes.object,
    excerpt: PropTypes.string,
    uri: PropTypes.string,
    author: PropTypes.object,
    slug: PropTypes.string,
    className: PropTypes.string,
};

export default BlogMain;
